<template>
	<div class="checkBoxWrap">
		<label>
			<input type="checkbox" :true-value="trueValue" :false-value="falseValue" :name="name" :id="name"
				v-model="value" />
			<div v-html="item.label" class="labelChb">
			</div>
		</label>
		<p class="errorMsg" v-show="showErrorMessage">{{ errorMsg }}</p>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import DataForInput from '@/store/repeatLoan/longFormOptions';

export default {
	name: "formCheckBox",

	props: {
		name: {
			type: String,
			default: '',
		},
		trueValue: {
			type: [Boolean, String],
			default: true
		},
		falseValue: {
			type: [Boolean, String],
			default: false
		},
	},

	data() {
		return {
			item: DataForInput[this.name],
		}
	},

	computed: {
		errorMsg() {
			return this.statusVuex[this.name].msg;
		},
		showErrorMessage() {
			return this.statusVuex[this.name].ShowError;
		},
		...mapState({
			statusVuex: state => state.repeatLoan.workData,
			valueVuex: state => state.repeatLoan.sendData,
		}),
		value: {
			get() {
				return this.valueVuex[this.name]
			},
			set(value) {
				this.$store.dispatch('repeatLoan/saveSendData', { target: this.name, value: value });
			}
		}
	}
}
</script>

<style scoped>
.checkBoxWrap {
	margin-bottom: 15px;
}
label {
	display: flex;
	width: 100%
}

label div {
	font-size: 2.65vw;
	color: #959595;
}

label div a {
	color: #43AC34;
}

input[type=checkbox] {
	background: #F5F5F5;
	appearance: none;
	position: relative;
	border-color: rgba(67, 172, 52, 0.3);
	border-style: solid;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

input[type=checkbox]::before {
	content: '';
	display: none;
	appearance: none;
	border-radius: 0;
	transform: rotate(45deg);
	border-right: 1px solid #0D5740;
	border-bottom: 1px solid #0D5740;
	margin-bottom: 1vw
}

input[type=checkbox]:checked::before {
	display: block;
}

input[type=checkbox] {
	max-width: 8.24vw;
	min-width: 8.24vw;
	height: 8.24vw;
	border-radius: 2px;
	margin-right: 2vw;
	border-width: 2px;
}

input[type=checkbox]::before {
	width: 2.1vw;
	height: 3.6vw;
	left: 1.93vw
}

.errorMsg {
	position: static
}

@media (min-width: 760px) {
	label {
		font-size: 1vw;
		align-items: center;
	}

	label span {
		font-size: 1.13vw;
	}

	label div {
		font-size: 1.13vw
	}

	input[type=checkbox] {
		max-width: 2.26vw;
		min-width: 2.26vw;
		height: 2.26vw;
		margin-right: 1vw
	}

	input[type=checkbox]::before {
		width: 0.5vw;
		height: 0.9vw;
		margin-bottom: 0.3vw
	}
}

@media (min-width: 1240px) {
	a {
		font-size: 14px
	}

	label {
		font-size: 1vw;
		align-items: center;
	}

	label>div {
		font-size: 14px;
	}

	label div {
		font-size: 14px
	}

	input[type=checkbox] {
		min-width: 28px;
		max-width: 28px;
		height: 28px;
		border-radius: 5px;
		margin-right: 10px
	}

	input[type=checkbox]::before {
		width: 7px;
		height: 13px;
		margin-bottom: 5px
	}
}
</style>
